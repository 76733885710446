import * as React from "react"
import moment from 'moment';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Link from "../Elements/Link"


const BlogItem = ({data}) => {
  const { title, short_description, image, publishing_date, readtimeinminutes, author, category} = data.node.data
  return (
    <div className="flex lg:flex-row flex-col items-center border-b lg:py-20 py-10 border-blue-900">
      <div className="lg:w-8/12 w-full 2xl:pr-32">
        {author && (
          <div className="flex gap-4 items-center mb-6">
            <img
              loading="lazy"
              alt={author?.document?.data?.author_name?.text}
              src={author?.document?.data?.image?.fixed?.srcWebp}
              srcSet={author?.document?.data?.image?.fixed?.srcSetWebp}
              width={65}
              height={65}
              className='mb-0'
            />
            <div>
              <p className="font-bold text-lg text-blue mb-0">{author?.document?.data?.author_name?.text}</p>
              <p className="text-base text-blue mb-0">{author?.document?.data?.designation && author.document.data.designation}</p>
            </div>
          </div>
        )}

        <div className="w-10/12">
          <h3 className="text-blue font-bold md:text-4xl text-2xl mb-5"><Link href={`/blog/${data.node.uid}`} className="text-blue font-bold md:text-4xl text-2xl no-underline">{title && title.text}</Link></h3>
          <p className="text-blue text-lg font-normal ">{short_description && short_description}</p>
          <div className="lg:flex gap-4 items-center hidden">
            <p className="text-gray-400 font-normal text-base m-0 ">{moment(publishing_date).format('MMM D, YYYY')} {readtimeinminutes && (`- ${readtimeinminutes}min read`)} </p>
            {category &&(
              <p className="text-white bg-blue rounded-full no-underline py-0.5 px-2 text-sm mb-0">{category?.document?.data?.name?.text}</p>
            )}

          </div>
        </div>
      </div>
      <div className="lg:w-4/12 w-full">
        <Link href={`/blog/${data.node.uid}`}>
          <GatsbyImage
            className="w-full rounded-2xl"
            image={getImage(image)}
            alt={title && title.text}
          />
        </Link>
        <div className="flex gap-4 items-start flex-col lg:hidden mt-6">
          <p className="text-gray-400 font-normal text-base m-0 ">{moment(publishing_date).format('MMM D, YYYY')} {readtimeinminutes && (`- ${readtimeinminutes}min read`)}</p>
          {category &&(
              <p className="text-white bg-blue rounded-full no-underline py-0.5 px-2 text-sm ">{category?.document?.data?.name?.text}</p>
            )}
        </div>
      </div>
    </div>
  )
}
export default BlogItem
