import React, { forwardRef } from 'react';

const TextField = ({ field, label, required, rows = 1, ...rest }, ref) => React.createElement(
  rows > 1 ? 'textarea' : 'input',
  {
    className: 'py-4 pl-4  block w-full mb-5 sm:text-sm border-gray-300 rounded-3xl',
    placeholder: `${label}${required ? '' : ' (Optional)'}`,
    ...rest,
    ref,
    required,
    rows,
  },
);

export default forwardRef(TextField);
