import React from 'react';
import { graphql } from 'gatsby';
import Layout from "../components/layout"
import SEO from "../components/seo"

import BlogIndex from '../components/Blog/BlogIndex'
const Blog = (props) => {
  const { data } = props
  const {
    allPrismicPosts, allPosts
  } = data;

  if (!allPrismicPosts) return null;

  return (
    <Layout theme='dark'>
      <SEO
        context={{
          ...props.pageContext,
          meta_title: 'Carriyo | Last Mile Blog',
        }}
        cpath={props.location.pathname}
      />
      <BlogIndex data={allPrismicPosts.edges} latestPosts={allPosts}/>
    </Layout>
  );
};



export default Blog;

export const data = graphql`
  query {
    allPosts: allPrismicPosts {
      edges {
        node {
          uid
          id
          data {
            readtimeinminutes
            title {
              text
              html
            }
            short_description
            publishing_date
            author {
              document {
                ... on PrismicPostAuthors {
                  id
                  data {
                    author_name {
                      text
                      html
                    }
                    designation
                    image {
                      fixed(width: 65, height: 65) {
                        srcSetWebp
                        srcWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicPosts {
        edges {
            node {
                uid
                id
                data {
                readtimeinminutes
                title {
                    text
                    html
                }
                short_description
                image {
                    gatsbyImageData(layout: FULL_WIDTH)
                }
                category {
                    document {
                    ... on PrismicPostsCategory {
                        id
                        data {
                        name {
                            text
                            html
                        }
                        }
                    }
                    }
                }
                publishing_date
                author {
                    document {
                    ... on PrismicPostAuthors {
                        id
                        data {
                        author_name {
                            text
                            html
                        }
                        designation
                        image {
                            fixed(width: 65, height: 65) {
                            srcSetWebp
                            srcWebp
                            }
                        }
                        }
                    }
                    }
                }
                }
            }
        }
    }
  }
`;
