import React, { useEffect, useState } from "react"
import { get, uniqBy } from 'lodash';
import moment from 'moment';

import searchIcon from '../../assets/images/search.png';

import { textSearcher } from '../../frontend-shared/helpers';

import Select from "../Elements/Select";
import StripeSection from "../Elements/StripeSection";
import TextField from "../Elements/TextField";

import BlogItem from "./BlogItem"
import HeroSection from "./HeroSection"
import RecentPost from "./Recentpost"

const publishingDate = (x) => get(x, 'node.data.publishing_date');

const sortByPublishingDate = (x, y) => (
  moment(publishingDate(y)).valueOf() - moment(publishingDate(x)).valueOf()
);

const sortByRankAndPublishingDate = ([rankX, x], [rankY, y]) => (
  rankX === rankY ? sortByPublishingDate(x, y) : rankY - rankX
);

const stepRecordsBy = 6;

const getMatchTexts = ({
  node: {
    data: {
      title,
      // short_description,
      author,
      publishing_date,
    },
  },
}) => [
  title?.text,
  author?.document?.data?.author_name?.text,
  moment(publishing_date).format('YYYY'),
  moment(publishing_date).format('MMM'),
].filter(Boolean);

const ALL_TOPICS = '_ALL';

const BlogIndex = ({ data: allPosts, latestPosts }) => {
  const [state, setState] = useState({
    category: ALL_TOPICS,
    searchText: '',
    shownRecords: stepRecordsBy,
  });
  const update = (next) => setState((prev) => ({ ...prev, ...next }));

  useEffect(() => {
    update({ shownRecords: stepRecordsBy });
  }, [state.searchText]);

  const matchRanker = textSearcher(state.searchText);

  const matchedRecords = allPosts
    .filter(({ node }) => (
      state.category === ALL_TOPICS
      || node?.data?.category?.document?.id === state.category
    )).map((post) => [
      state.searchText ? getMatchTexts(post).reduce((sum, text) => sum + matchRanker(text), 0) : 1,
      post,
    ]).filter(([rank]) => rank > 0)
    .sort(sortByRankAndPublishingDate)
    .map(([, post]) => post);

  const totalRecords = matchedRecords.length;

  const loadMore = () => update({
    shownRecords: Math.min(totalRecords, state.shownRecords + stepRecordsBy),
  });

  const hasMore = state.shownRecords < totalRecords;

  return (
    <>
      <HeroSection />
      <div className="background1 md:pb-40 pb-24">
        <StripeSection title="Highlights">
          <div className="xl:pt-20 pb-8 pt-12">
            <RecentPost allPosts={latestPosts?.edges?.sort(sortByPublishingDate)} />
          </div>
        </StripeSection>
        <StripeSection title="All Posts">
          <form className="formblock w-full flex items-center justify-center gap-6 mx-auto max-w-2xl pt-24 pb-6">
            <div className="relative w-full">
              <TextField
                className="py-4 pl-10 block w-full text-md border-gray-300 rounded-full placeholder-current placeholder-opacity-50"
                onChange={({ target }) => update({ searchText: target.value })}
                placeholder="Search"
                value={state.searchText}
              />
              <img
                className="absolute left-3 top-1/2 transform -translate-y-1/2 mb-0"
                src={searchIcon}
                width={16}
                height={16}
              />
            </div>
            <Select
              options={[{
                label: 'All Topics',
                value: ALL_TOPICS,
              }, ...uniqBy(
                 allPosts
                  .map(({ node }) => node?.data?.category?.document)
                  .filter((document) => document?.data?.name?.text)
                  .map(({ id, data }) => ({ label: data.name.text, value: id })),
                'label',
              )]}
              onChange={({ target }) => update({ category: target.value })}
              value={state.category}
            />
          </form>
          <div className="relative">
            {matchedRecords.slice(0, state.shownRecords).map((article) => (
              <BlogItem key={article.id} data={article} />
            ))}
          </div>
          <div className="flex items-center justify-center md:mt-14 mt-8">
            {hasMore ? (
              <button onClick={loadMore} className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block">Load More</button>
            ) : (
              <p className="bg-white shadow-lg font-medium sm:text-xl text-base py-3 px-7 text-blue rounded-full no-underline mt-4 tracking-wide uppercase inline-block">No more results</p>
            )}
          </div>
        </StripeSection>
      </div>
    </>
  );
};

export default BlogIndex;
