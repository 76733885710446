import * as React from "react"
import moment from 'moment';

import Link from "../Elements/Link";

const Recentpost = ({ allPosts = [] }) => (
  <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-4 gap-10">
    {allPosts.slice(0, 3).map((item, index)=>{
      const { title, short_description, image, readtimeinminutes, publishing_date, author, category} = item.node.data
      return(
        <div className="h-full flex flex-col" key={index}>
          <h2 className="font-medium text-3xl text-blue mb-3">0{index+1}</h2>
          <div className="bg-white shadow-xl rounded-2xl lg:py-10 py-5 sm:px-10 px-4 h-full pb-12">
            {author && (
              <div className="flex gap-4 items-center mb-5">
                <img alt={author?.document?.data?.author_name?.text} loading="lazy" src={author?.document?.data?.image && author.document.data.image.fixed.srcWebp} srcSet={author?.document?.data?.image && author.document.data.image.fixed.srcSetWebp} width={65} height={65} className='mb-0'/>
                <div>
                  <p className="font-bold sm:text-lg text-base text-blue mb-0">{author?.document?.data?.author_name && author.document.data.author_name.text}</p>
                  <p className="text-blue mb-0 sm:text-base text-sm">{author?.document?.data?.designation && author.document.data.designation}</p>
                </div>
              </div>
            )}
            <div>
              <h3 className="text-blue font-bold text-xl mb-5"><Link href={`/blog/${item.node.uid}`} className="text-blue no-underline font-bold">{title && title.text}</Link></h3>
              <p className="text-gray-400 font-normal text-base mb-0">{moment(publishing_date).format('MMM D, YYYY')} {readtimeinminutes && (`- ${readtimeinminutes}min read`)}</p>
            </div>
            <div></div>
          </div>
        </div>
      )
    })}
  </div>
);

export default Recentpost
