import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image';

const HeroSection = () => {
    const { ShipmentLeft, shipmentleftmobile } = useStaticQuery(
        graphql`
          query {
           
            ShipmentLeft: file(relativePath: {eq: "shipment-left.png"}) {
                childImageSharp {
                    fixed(width: 480, height:380) {
                        srcWebp
                        srcSetWebp
                    }
                }
            }

            shipmentleftmobile: file(relativePath: {eq: "shipment-left.png"}) {
                childImageSharp {
                    fixed(width: 480, height:380) {
                        srcWebp
                        srcSetWebp
                    }
                }
            }

          }
        `
    )
    return (
        <div className="bg-gradient sm:pt-32 pt-28 pb-0">
            <div className="max-w-6xl mx-auto">
                <div className="flex md:flex-row flex-col-reverse items-center md:items-end">
                    <div className="md:w-3/5 w-full mb-16 px-4 ">
                        <h5 className="text-lg text-green-carriyo mb-3">Blog</h5>
                        <h1 className="text-white lg:text-7xl md:text-6xl sm:text-5xl text-4xl font-medium uppercase mb-0">the last mile</h1>
                    </div>
                    <div className="md:w-2/5 w-full flex justify-end">
                    <img
                      loading="lazy"
                      alt="shipment-left"
                      src={ShipmentLeft.childImageSharp.fixed.srcWebp}
                      width="480"
                      height="380"
                      className="md:block hidden mb-0"
                    />
                    <img
                      loading="lazy"
                      alt="shipment-left-mobile"
                      src={shipmentleftmobile.childImageSharp.fixed.srcWebp}
                      width="220"
                      height="280"
                      className="md:hidden block mb-0"
                    />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeroSection
