import React, { forwardRef } from 'react';

const Select = ({
  field, label, options, value: selected, ...rest
}, ref) => (
  <>
    {label && (
      <p className="text-sm text-blue font-normal m-0 pt-5 pb-2">
        {label}
      </p>
    )}
    <select
      className="py-4 pl-4  block w-full sm:text-sm border-gray-300 rounded-full"
      {...rest}
      ref={ref}
    >
      {options.map(({ label, value }) => (
        <option
          disabled={!value}
          selected={selected === value}
          value={value}
        >
          {label}
        </option>
      ))}
    </select>
  </>
);

export default forwardRef(Select);
